import React from "react";
import { Helmet } from "react-helmet";

import { hero } from "./thanks.module.scss";

export default function Thanks() {
  return (
    <>
      <Helmet>
        <title>Fresh Brewed Design - Contact</title>
      </Helmet>
      <div className={hero}>Thanks!</div>
      <p style={{ textAlign: "center" }}>I&apos;ll get back to you soon.</p>
    </>
  );
}
